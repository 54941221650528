let firebaseConfig;

if (process.env.NODE_ENV !== 'production') {
    firebaseConfig = {
        apiKey: "AIzaSyD05_Ol0zocqnYK5FY95Wh__vlK45eXLdw",
        authDomain: "dynki-c5141.firebaseapp.com",
        databaseURL: "https://dynki-c5141.firebaseio.com",
        projectId: "dynki-c5141",
        storageBucket: "dynki-c5141.appspot.com",
        messagingSenderId: "957203868799",
        appId: "1:957203868799:web:2277a1f4c246166f",
        measurementId: "G-Y5T1DLRYY1"
    };
} else {
    // Firebase config
    firebaseConfig = {
        apiKey: "AIzaSyAtAqNDm-HnkFMf4T8mOj4lxU4whaJInOs",
        authDomain: "dynki-prod.firebaseapp.com",
        databaseURL: "https://dynki-prod.firebaseio.com",
        projectId: "dynki-prod",
        storageBucket: "dynki-prod.appspot.com",
        messagingSenderId: "633533703332",
        appId: "1:633533703332:web:78ead79c2913f67fdd3231",
        measurementId: "G-EMB48TNFNJ"
    }
}

export default firebaseConfig;

